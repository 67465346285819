import * as React from "react";
import { Row, Col, Modal, Form, Icon } from "antd";
import { Property, Inspection } from "../../../../../models";
import { FormComponentProps } from "antd/lib/form";
import PropertyDetailFormData from "./PropertyDetailFormData";
import AddressFormData from "./AddressFormData";

export interface EditDialogProps extends FormComponentProps {
  Property: Property;
  inspection: Inspection;
  visible: boolean;
  onCancel: () => void;
  onCreate: (property: Property, inspection: Inspection) => void;
}

class PropertyEditDialog extends React.Component<EditDialogProps, any> {
  constructor(props: EditDialogProps) {
    super(props);
  }

  onOk = () => {
    const { getFieldValue } = this.props.form;
    const newProp: Property = {
      street: getFieldValue("property-data-street"),
      streetNumber: getFieldValue("property-data-streetNr"),
      municipality: getFieldValue("property-data-municipality"),
      municipalityCode: getFieldValue("property-data-municipalityCode"),
      usageKind: getFieldValue("property-data-usageKind"),
      assuranceNumber: getFieldValue("property-data-assuranceNumber"),
      administrator: {
        street: getFieldValue("property-administrator-street"),
        streetNumber: getFieldValue("property-administrator-streetNr"),
        zipCode: getFieldValue("property-administrator-zipCode"),
        city: getFieldValue("property-administrator-city"),
        firstname: getFieldValue("property-administrator-firstname"),
        name: getFieldValue("property-administrator-name"),
      },
    };

    this.props.inspection.affectedBuildingParts = getFieldValue(
      "property-data-affectedBuildingParts"
    );
    this.props.inspection.subsidyNumber = getFieldValue(
      "property-data-subsidyNumber"
    );
    this.props.inspection.permitNumber = getFieldValue(
      "property-data-permitNumber"
    );
    this.props.inspection.systemInstaller = getFieldValue(
      "property-data-systemInstaller"
    );

    this.props.onCreate(newProp, this.props.inspection);
  };

  render() {
    let property = this.props.Property || {};

    const { visible, onCancel, onCreate } = this.props;

    const { administrator = {} } = property || {};

    const title = (
      <div>
        <Icon type="home" />
        &nbsp;<span>Gebäude</span>
      </div>
    );

    return (
      <Modal
        title={title}
        width={1000}
        onOk={this.onOk}
        okText="Speichern"
        onCancel={onCancel}
        visible={visible}
      >
        <Form labelAlign="left">
          <Row>
            <Col lg={12} sm={24}>
              <PropertyDetailFormData
                form={this.props.form}
                property={this.props.Property}
                inspection={this.props.inspection}
              />
            </Col>
            <Col lg={12} sm={24}>
              <AddressFormData
                title="Verwalter"
                formFieldIdPrefix="property-administrator"
                form={this.props.form}
                address={administrator}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create<EditDialogProps>()(PropertyEditDialog);
