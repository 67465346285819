import { Alert, List, Upload, Icon, Spin } from "antd";
import { UploadProps } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import {
  createDocument,
  deleteDocument,
  getDocumentsByInspection,
  uploadDocument,
} from "../../../../../../../../endpoints";
import { DocumentSource, Inspection } from "../../../../../../../../models";

interface Props extends UploadProps {
  inspection: Inspection;
  propertyId?: string;
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const UploadZone: React.FunctionComponent<Props> = (props) => {
  const [defaultFiles, setDefaultFiles] = useState<UploadFile[]>(null);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null);
  const [isUploading, setIsUploadin] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    setIsUploadin(true);
    setUploadErrorMessage(null);

    if (!props.inspection || !props.propertyId) {
      setUploadErrorMessage("Vor dem Upload muss ein Gebäude erfasst werden.");
    }

    acceptedFiles.forEach(async (file: File) => {
      try {
        const doc = await createDocument({
          name: file.name,
          property: props.propertyId,
          inspection: props.inspection.id,
          documentSource: DocumentSource.InspectionUpload,
        });

        const response = await uploadDocument(doc.uri, file);

        if (response.status !== 201) {
          await deleteDocument(doc.id);
          const message = `${response.status} - ${response.statusText} - ${response.data}`;
          setUploadErrorMessage(message);
        }

        loadDocuments();
        setIsUploadin(false);
      } catch (error) {
        setUploadErrorMessage(error.message);
        setIsUploadin(false);
      }
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop: onDrop });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const loadDocuments = async () => {
    const documents = await getDocumentsByInspection(props.inspection.id);
    const defaultFileList: UploadFile[] = documents.map((doc) => ({
      uid: doc.id,
      name: doc.name,
      status: "done",
      url: doc.uri,
      size: 0,
      type: "image",
    }));
    setDefaultFiles(defaultFileList);
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  return (
    <>
      <div>
        <Spin tip="Datei wird hochgeladen ..." spinning={isUploading}>
          <div
            {...getRootProps({
              //@ts-ignore
              style,
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Dateien hier loslassen.</p>
            ) : (
              <p>Klicken oder Dateien in dieses Feld ziehen.</p>
            )}
          </div>
        </Spin>
      </div>
      <div>
        {uploadErrorMessage && (
          <Alert
            message="Upload fehlgeschlagen"
            description={uploadErrorMessage}
            type="error"
            closable
            onClose={() => setUploadErrorMessage(null)}
          />
        )}
        {defaultFiles && defaultFiles.length > 0 && (
          <List
            itemLayout="vertical"
            size="small"
            dataSource={defaultFiles}
            renderItem={(item) => (
              <List.Item
                extra={
                  <Icon
                    theme="twoTone"
                    type="delete"
                    style={{ width: 32, height: 32 }}
                    onClick={async () => {
                      //@ts-ignore
                      await deleteDocument(item.uid);
                      loadDocuments();
                    }}
                  />
                }
              >
                <a href={item.url}>{item.name}</a>
              </List.Item>
            )}
          />
        )}
      </div>

      {/* {
        defaultFiles && (<Dragger {...props} defaultFileList={defaultFiles}
          customRequest={(options: any) => {
            handleCustomUploadRequest(options, props.inspection)
          }}
          onRemove={file => {
            //@ts-ignore
            if (file.originFileObj && file.originFileObj.newuid) {
              //@ts-ignore
              deleteDocument(file.originFileObj.newuid);
            }
            else {
              deleteDocument(file.uid);
            }

          }}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Dateiupload
      </p>
        </Dragger>)
      } */}
    </>
  );
};

UploadZone.defaultProps = {
  listType: "text",
  multiple: false,
  onChange: ({ fileList }) => console.log("uploaded: " + fileList),
};

export default UploadZone;
